<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
      class="mb-2"
    >{{message}}</vs-alert>

    <form class="postion-icon pt-4">
      <label for="email">Email</label>
      <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        id="email"
        icon-no-border
        icon-pack="feather"
        v-model="user.email"
        class="w-full mt-1 pr-0"
      />
      <span class="text-danger text-sm">{{ errors.first('email') }}</span>

      <div class="passwordField mt-8">
        <label for="password">Password</label>
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        :type="passwordFieldType"
        name="password"
        id="password"
        icon-no-border
        icon-pack="feather"
        :icon="passwordShowIcon"
        v-model="user.password"
        class="passwordInput mt-1 pr-0 w-full"
        @click.native="switchPasswordVisibility($event)"
        @keyup.enter="loginAdmin"
        autocomplete="chrome-off"
      />

      <span class="text-danger text-sm">{{ errors.first('password') }}</span>
      </div>
    </form>

    <div class="flex flex-col flex-wrap justify-between mt-4 mb-6">
      <router-link to="/forgotPassword" class="mb-8">Forgot Password?</router-link>
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3 ml-0 mr-0" >Remember Me</vs-checkbox>
    </div>

    <div class="flex flex-wrap justify-between mb-0">
      <vs-button class="w-full round" size="large" :disabled="!validateForm" @click="loginAdmin">Log in</vs-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loginStatus: false,
      showDismissibleAlert: false,
      message: "",
      passwordFieldType: "password",
      passwordShowIcon: "icon icon-eye-off",
      iconWrap: {
        marginTop: '-35px'
      },
      checkbox_remember_me: $cookies.get('rememberMe') || false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    ...mapActions("auth", ["loginJWT", "getUserByRememberMeToken"]),
    loginAdmin() {
      
      const payload = {
        rememberMe: JSON.parse(this.checkbox_remember_me),
        email: this.user.email,
        password: this.user.password,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.loginJWT(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.$router.push("/dashboard");
            })
            .catch((error) => {
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
            });
        }
      }).catch(()=>{});
    },

    switchPasswordVisibility(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordShowIcon =
        this.passwordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
           }
      return true;
    }
  },
  created(){
    if(this.checkbox_remember_me){
      let rememberMeToken = $cookies.get('rememberMeToken') 
      this.getUserByRememberMeToken({rememberMeToken}).then( res => {
        this.$router.push("/dashboard")
      })
    }
  }
};
</script>
