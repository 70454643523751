<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col w-11/12 md:w-4/5 lg:w-1/2">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full py-8 px-12 md:p-0 md:w-1/2 text-center">
              <img v-if="appName === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="65%" height="auto" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName === 'RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="65%" height="auto" alt="Rellopay" class="mx-auto"/>
              <h3 class="md:hidden text-xl md:text-2xl pt-6">Log in to your account</h3>
            </div>

            <div class="vx-col w-full md:w-1/2">
              <div class="pt-2 pb-4 sm:pb-6 px-6 sm:px-10 md:pt-10 md:pb-12 md:px-10 login-tabs-container bg-white">
                <div class="vx-card__title mb-12 md:mb-4 text-center md:text-left hidden md:block">
                  <h3 class="text-xl md:text-2xl">Log in to your account</h3>
                </div>
                
                <login-jwt></login-jwt>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import LoginJwt from "./LoginJWT.vue";

export default {
  components: {
    LoginJwt
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
    }
  }
};
</script>